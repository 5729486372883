import { Auth0Provider } from "@auth0/auth0-react";
import { createContext, useContext, useState } from "react";
import { usePage } from "~core/hooks/use-page";
import { useAuth0Env } from "~features/auth/hooks/use-auth0-env";

export const ProvideAuth0 = ({ children }) => {
  // This can be injected at the router level
  const { pageData } = usePage();
  const { returnTo } = pageData?.props || {};

  const [redirectCallbackParams, setRedirectCallbackParams] = useState({ returnTo: "" });

  /**
   * Get the Auth0 app config, which is determined by either the PUBLIC_AUTH0_APP env variable
   * or the user's current flags (e.g. ?flag=auth0:staging)
   *
   * We set the immediate flag to true to ensure the Auth0 app is configured before the DOM loads
   */
  const auth0App = useAuth0Env(true);

  return (
    <Auth0CallbackContext.Provider value={redirectCallbackParams}>
      <Auth0Provider
        // Config variables for Access Code Flow with PKCE
        domain={auth0App.domain}
        clientId={auth0App.clientId}
        // Cache the user's session in localstorage for better UX
        cacheLocation="localstorage"
        // Use refresh tokens to keep the user logged in
        useRefreshTokens={true}
        // Default parameters for the Auth0 /authorize url
        authorizationParams={{
          audience: auth0App.apiAudience,
          scope: "openid profile email",

          // Default page to redirect to after login
          redirect_uri:
            // Use current page's origin
            ((typeof window !== "undefined" && window.location.origin) ||
              // Fallback for SSR
              import.meta.env.BASE_URL) +
            // Default to the callback route
            "/callback/",
        }}
        // Using refresh tokens are ideal but it's possible that the user's refresh token may be consumed or invalidated by
        // other apps or sessions, leading to a invalid_grant error ("Unknown or invalid refresh token").
        // This flag lets us fall back to an iframe-based token flow when that happens.
        // https://github.com/auth0/auth0-react/issues/508#issuecomment-1466779009
        useRefreshTokensFallback={true}
        // This handler is called after the user logs in or signs up
        // More specifically, it runs when a page url has a code and state parameter
        onRedirectCallback={(appState, user) => {
          const { pathname } = window.location;
          // Setting the login info to be retrieved when a non-callback page is loaded, so we can push user ID into datalayer.
          // We lost user ID when we transitioned from Piano to Auth0.
          // Only supporting localStorage method.
          // TODO: Add user creation date here to report registrations in the frontend
          localStorage.setItem("sa_auth_event", 1);

          // Remove the code and state parameters from the URL
          window.history.replaceState({}, document.title, pathname);

          const returnToPath = appState?.returnTo || returnTo?.path;
          setRedirectCallbackParams({ appState, user, returnTo: returnToPath });

          // If we're on the callback route, let it handle the redirect instead
          if (pathname === "/callback/") return;
          // If this function is called on any other page, let's do the redirect logic here:

          // If there's no returnTo URL provided, do nothing
          // Also prevents accidental circular redirects
          if (!returnToPath || returnToPath === pathname) return;

          // Redirect to the returnTo URL after 5s
          setTimeout(() => {
            window.location.href = returnToPath;
          }, 5 * 1000);
        }}
        children={children}
      />
    </Auth0CallbackContext.Provider>
  );
};

/**
 * @typedef {Object} Auth0CallbackContextValue
 * @property {import('@auth0/auth0-react').AppState | undefined} appState
 * @property {import('@auth0/auth0-react').User | undefined} user
 * @property {string} returnTo
 */

/** @type {React.Context<Auth0CallbackContextValue>} */
const Auth0CallbackContext = createContext({ appState: undefined, user: undefined, returnTo: "" });

/** @returns {Auth0CallbackContextValue} */
export const useAuth0CallbackParams = () => useContext(Auth0CallbackContext);

export default ProvideAuth0;
